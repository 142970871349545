import React, { useEffect } from 'react';
import { flow } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useNotification from '@giro/shared-hooks/useNotification.hook';
import useIsCreaHook from '@giro-pdv/hooks/useIsCrea.hook';
import useIsMobile from './hooks/useIsMobile.hook';

import PDVModule from '@giro-pdv/modules/pdv/PDV.module';
import ReportsModule from '@giro-pdv/modules/reports/Reports.module';
import LoginModule from '@giro-pdv/modules/login/Login.module';
import ReceiptModule from './modules/receipt/Receipt.module';
import MonitoringModule from './modules/monitoring/Monitoring.module';
import SetupModule from './modules/setup/Setup.module';

import DialogReceiptComponent from './components/DialogReceipt.component';

import ROUTES from './constants/routes.constant';

import menu from './store/menu';

const Routes = () => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { user, signedIn } = useAuth();
  const dispatch = useDispatch();

  const dispatchRedux = {
    close: flow(menu.action.close, dispatch),
  };

  useNotification();

  useEffect(() => {
    if (isMobile) {
      dispatchRedux.close();
    }
  }, [pathname, isMobile]);

  useEffect(() => {
    if (signedIn && process.env.REACT_APP_HOTJAR === 'true') {
      const { user: userState } = user || {};
    }
  }, [signedIn]);

  const isCrea = useIsCreaHook();

  const ROOTROUTER = isCrea ? ROUTES.REPORTS.ROOT : ROUTES.PDV.ROOT;

  return (
    <>
      <Switch>
        {!isCrea && <Route path={ROUTES.PDV.ROOT} component={PDVModule} />}
        <Route path={ROUTES.REPORTS.ROOT} component={ReportsModule} />
        <Route path={ROUTES.LOGIN.ROOT} component={LoginModule} />
        <Route path={ROUTES.RECEIPT.ROOT} component={ReceiptModule} />
        <Route path={ROUTES.MONITORING.ROOT} component={MonitoringModule} />
        <Route path={ROUTES.SETUP.ROOT} component={SetupModule} />

        <Redirect to={ROOTROUTER} />
      </Switch>

      <DialogReceiptComponent />

      {isCrea && (
        <Helmet>
          <script
            id="webchat__widget"
            src="https://cdn.chatapi.net/webchat/widget/widget.js?cId=1d1cb5482d5558a50061f01f586ef2cf6797aa73c3680eb5047b201b0c4b84fe"
          ></script>
        </Helmet>
      )}

      {!isCrea && (
        <Helmet>
          <script
            id="webchat__widget"
            src="https://cdn.chatapi.net/webchat/widget/widget.js?cId=1d1cb5482d5558a50061f01f586ef2cf6797aa73c3680eb5047b201b0c4b84fe"
          ></script>
        </Helmet>
      )}
    </>
  );
};

export default Routes;
