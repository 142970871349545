import React from 'react';
import { flow } from 'lodash';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import { BsPlusSlashMinus } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Paper } from '@mui/material';
import useIsMobile from '@giro-pdv/hooks/useIsMobile.hook';

import dialogReceipt from '@giro-pdv/store/dialogReceipt';

import reports from '../store/reports';
import { IconContext } from 'react-icons/lib';

const rows = [
  {
    dtTransacao: '2022-06-10T00:01:37.783',
    cdCliente: 1361,
    nmFantasia: 'CREA-SP',
    nmUsuario: 'CREA-SP',
    vlTransacao: 93.69,
    vlLiberado: 88.78,
    tpPagamento: 'Crédito',
    qtdParcelas: 1,
    tabela: 'CREA',
    nmSegmento: 'CREA',
    idTransacao: '000008852025',
    cdTransacao: 124104126,
    mensagemTransacao: 'Transação aprovada.',
    status: 'Aprovada',
    pago: false,
    cdBarras: null,
    nmBandeira: 'Visa',
    nmAdquirente: 'Stone',
    detran: 0,
  },
];

export default function TableReportCreaComponent() {
  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const dispatch = useDispatch();

  const selectorRedux = {
    data: useSelector(reports.selector.selectData),
    loading: useSelector(reports.selector.selectLoading),
  };

  const dispatchRedux = {
    updateFilters: flow(reports.action.updateFilters, dispatch),
    resetState: flow(reports.action.resetState, dispatch),
    receiptOpen: flow(dialogReceipt.action.open, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.updateFilters({
      type: 'ec',
    });

    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const indexOfLast = currentPage * limit;
  const indexOfFirst = indexOfLast - limit;
  const currentData = (selectorRedux.data || []).slice(
    indexOfFirst,
    indexOfLast
  );

  const renderTableCell = (el, idx, row) => {
    const isDate = ['dtTransacao'];
    const isAmount = ['vlTransacao', 'vlLiberado'];
    const isCustom = ['receipt'];

    if (isCustom.includes(el)) {
      if (el === 'receipt') {
        return (
          <TableCell component="th" scope="row" key={`${el}-${idx}`}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ height: 32, width: 32, alignSelf: 'flex-end' }}
              onClick={() => dispatchRedux.receiptOpen(row.cdTransacao)}
              disabled={row.status !== 'Aprovada'}
            >
              <svg
                width="16"
                height="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#printer" />
              </svg>
            </Button>
          </TableCell>
        );
      }
    }

    if (isDate.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {format(new Date(row[el].replace('Z', '')), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </TableCell>
      );
    }

    if (isAmount.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {Number(row[el]).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell component="th" scope="row" key={`${el}-${idx}`}>
        <Typography variant="trasso_body_extra_small" color="trasso.purple.100">
          {row[el]}
        </Typography>
      </TableCell>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="300px"
      position="relative"
    >
      {selectorRedux?.data?.length > 0 && !selectorRedux.loading && (
        <Box p={2}>
          <Box
            paddingBottom={3}
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                // minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
            >
              <Box
                bgcolor="#7460ee"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <BsPlusSlashMinus />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography variant="trasso_body_medium" color="trasso.blue">
                  {Number((selectorRedux.data || []).length)}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="trasso.gray.40"
                >
                  Quantidade
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                boxShadow: 1,
                display: 'inline-flex',
                justifyContent: 'flex-start',
                minWidth: '250px',
              }}
              display="grid"
              gridTemplateColumns="1fr 1fr"
            >
              <Box
                bgcolor="#ffb22b"
                sx={{
                  p: 3,
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                  <MdOutlineAttachMoney />
                </IconContext.Provider>
              </Box>
              <Box sx={{ p: 1 }} display="grid" gridTemplateRows="1fr 1fr">
                <Typography variant="trasso_body_medium" color="trasso.blue">
                  {Number(
                    (selectorRedux.data || []).reduce((a, v) => {
                      return a + v.vlLiberado;
                    }, 0)
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
                <Typography
                  variant="trasso_heading_extra_small"
                  color="trasso.gray.40"
                >
                  Valor Total
                </Typography>
              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{ minWidth: { xl: '100%', md: '1300px' } }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {[
                    'Usuário',
                    'Giro Id',
                    'Data',
                    'Valor transação',
                    'Valor liberado',
                    'Tipo',
                    'Parcelas',
                    'Status',
                    '',
                  ].map((el, idx) => (
                    <TableCell key={`row-${idx}`}>
                      <Typography
                        variant="trasso_heading_extra_small"
                        color="trasso.purple.100"
                      >
                        {el}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((row) => (
                  <TableRow
                    key={row.dtTransacao}
                    sx={{
                      cursor: 'pointer',
                      '&:nth-of-type(odd)': {
                        backgroundColor: 'trasso.gray.20',
                      },
                      '&:nth-of-type(even)': {
                        backgroundColor: 'white',
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },

                      '&:hover': {
                        backgroundColor: 'trasso.gray.40',
                      },
                    }}
                  >
                    {[
                      'nmUsuario',
                      'idTransacao',
                      'dtTransacao',
                      'vlTransacao',
                      'vlLiberado',
                      'tpPagamento',
                      'qtdParcelas',
                      'status',
                      'receipt',
                    ].map((el, idx) => renderTableCell(el, idx, row))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={selectorRedux.data?.length || 0}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={(ev, nextPage) =>
                      setCurrentPage(nextPage + 1)
                    }
                    onRowsPerPageChange={(ev: any) => setLimit(ev.target.value)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      {selectorRedux?.data?.length === 0 && !selectorRedux.loading && (
        <Typography>Nenhum dado encontrado para essa pesquisa.</Typography>
      )}

      {selectorRedux.loading && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
