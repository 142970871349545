import React, { useState } from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldLabelFormikComponent from '@giro-pdv/components/FieldLabelFormik.component';

import TextFieldLabelComponent from '@giro-pdv/components/TextFieldLabel.component';

import PDVLayout from '../layouts/PDV.layout';

import UpdateAmountComponent from '../components/UpdateAmount.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const FormAssessoria = () => {
  const dispatch = useDispatch();
  const { user } = useAuthHook();
  const [barCode, setBarCode] = useState('');
  const [domain, setDomain] = useState('');

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
  };

  const initialValues = {
    client: null,
    total: 0,
    segment: '11',
    type_tax: 'client',
    cdBarras: '',
  };

  const onSubmit = () => {};

  const selectDomain = () => {
    switch (user.user.codes.idClient) {
      case 2589:
        setDomain('advantoniosamuel');
        break;
      case 2607:
        setDomain('caperbrasil');
        break;
      case 2611:
        setDomain('stadler');
        break;
      case 2613:
        setDomain('icrcobranca');
        break;
      case 2614:
        setDomain('camargoadvs');
        break;
      case 2615:
        setDomain('grupobjl');
        break;
      case 2616:
        setDomain('vellosoadv');
        break;
      case 2617:
        setDomain('gomesadvogados');
        break;
      case 2618:
        setDomain('mmjadv');
        break;
      case 2620:
        setDomain('facildepagar');
        break;
      case 2624:
        setDomain('perezadvocacia');
        break;
      case 2625:
        setDomain('guimaraessanches');
        break;
      case 2903:
        setDomain('portocred');
        break;
      case 3000:
        setDomain('trigg');
        break;
      case 3014:
        setDomain('sf3');
        break;
      case 3077:
        setDomain('omni');
        break;

      default:
        setDomain('giro');
        break;
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            {selectDomain()}
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <FieldLabelFormikComponent
                  size="small"
                  name="client"
                  label="Identificação do cliente"
                />
                <FieldLabelFormikComponent
                  size="small"
                  name="cdBarras"
                  label="Código de barras"
                  onChange={(e) => setBarCode(e.target.value)}
                  value={barCode}
                />
                <FieldCurrencyComponentDigits
                  customInput={TextFieldLabelComponent}
                  size="small"
                  name="amount"
                  label="Valor"
                  onChange={(val) => setFieldValue('total', Number(val))}
                />
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://app.giropagamentos.com.br/debts/${domain}/${barCode}`}
                  >
                    https://app.giropagamentos.com.br/debts/{domain}/
                    <br />
                    {barCode}
                  </a>
                </div>
              </Box>
              {/* <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                <Button
                  variant="contained"
                  loading={selectorRedux.transactionsLoading}
                  onClick={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabled={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                >
                  Pagar
                </Button>
              </Box> */}
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const AssessoriaScreen = () => {
  return (
    <PDVLayout>
      <FormAssessoria />
    </PDVLayout>
  );
};

export default AssessoriaScreen;
